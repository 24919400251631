"use strict";
window.addEventListener("DOMContentLoaded", init);
function init() {
    changeHeader();
    mvParticle();
}
function changeHeader() {
    var headerEl = document.querySelector(".c-header");
    var logoEl = document.querySelector(".c-header__logo img");
    var mv = document.querySelector(".c-mv");
    if (headerEl && logoEl) {
        if (headerEl.classList.contains("isTop")) {
            if (mv) {
                window.onscroll = function () {
                    if (window.scrollY >= mv.clientHeight / 2) {
                        headerEl.classList.add("is-active");
                        if (logoEl instanceof HTMLImageElement) {
                            logoEl.src = "./images/logo-c.svg";
                        }
                    }
                    else {
                        headerEl.classList.remove("is-active");
                        if (logoEl instanceof HTMLImageElement) {
                            logoEl.src = "./images/logo.svg";
                        }
                    }
                };
            }
        }
        else {
            headerEl.classList.add("is-active");
            if (logoEl instanceof HTMLImageElement) {
                var logoTmp = logoEl.src.replace("logo", "logo-c");
                logoEl.src = logoTmp;
            }
        }
    }
}
function mvParticle() {
    var mvEl = document.querySelector("#js-particle");
    if (mvEl) {
        particlesJS("js-particle", {
            particles: {
                number: {
                    value: 100,
                    density: {
                        enable: true,
                        value_area: 800,
                    },
                },
                color: {
                    value: "#ffffff",
                },
                opacity: {
                    value: 0.5,
                    random: false,
                    anim: {
                        enable: false,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false,
                    },
                },
                size: {
                    value: 3,
                    random: true,
                    anim: {
                        enable: false,
                        speed: 10,
                        size_min: 0.1,
                        sync: false,
                    },
                },
                line_linked: {
                    enable: true,
                    distance: 150,
                    color: "#FFF",
                    opacity: 0.4,
                    width: 2,
                },
                move: {
                    enable: true,
                    speed: 1,
                    direction: "none",
                    random: false,
                    straight: false,
                    out_mode: "out",
                    bounce: false,
                },
            },
            interactivity: {
                detect_on: "canvas",
                events: {
                    resize: true,
                },
            },
            retina_detect: true,
        });
    }
}
//  SPヘッダーハンバーガーメニュー表示
(function () {
    // 要素取得
    var hamburgerMenu = document.getElementById("HamburgerMenu");
    var header = document.getElementById("HeaderJs");
    var headerEl = document.querySelector(".c-header");
    var logoEl = document.querySelector(".c-header__logo img");
    var mv = document.querySelector(".c-mv");
    var body = document.body;
    // デバイス判定処理
    var isSpDevice = /iPhone|Android.+Mobile/.test(navigator.userAgent);
    // ハンバーガーメニューがクリックされたらトグル処理
    if (isSpDevice && hamburgerMenu) {
        hamburgerMenu.addEventListener("click", function () {
            if (header && logoEl && headerEl) {
                header.classList.toggle("is-active");
                body.classList.toggle("hidden");
                if (header.classList.contains("is-active")) {
                    var logoTmp = logoEl.src.replace("logo-c", "logo");
                    logoEl.src = logoTmp;
                }
                else {
                    var logoTmp = logoEl.src.replace("logo", "logo-c");
                    logoEl.src = logoTmp;
                }
                if (mv) {
                    if (window.scrollY <= mv.clientHeight / 2) {
                        headerEl.classList.toggle("is-active");
                        logoEl.src = header.classList.contains("is-active") ? "./images/logo.svg" : "./images/logo.svg";
                    }
                    else {
                        logoEl.src = header.classList.contains("is-active") ? "./images/logo.svg" : "./images/logo-c.svg";
                    }
                }
            }
        });
    }
})();
